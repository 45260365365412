<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Register</h1>

        <p v-if="error">
        {{ error }}
        </p>

        <b-form @submit="onSubmit" @reset="onReset">

          <b-form-group id="email-group" label="Email:" label-for="email">
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              placeholder="myname@example.com"
              aria-describedby="email-group-feedback"
              :state="emailFormatOkay"
              required>
            </b-form-input>
            <b-form-invalid-feedback id="email-group-feedback">
              Please ensure email is correct
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="password-group" label="Password:" label-for="password">
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              placeholder="Super Secure Secret"
              aria-describedby="password-group-feedback"
              :state="confirmationMatch"
              required>
            </b-form-input>
            <b-form-invalid-feedback id="password-group-feedback">
              Please ensure password and confirmation match
            </b-form-invalid-feedback>

          </b-form-group>

          <b-form-group id="password-confirm-group" label="Repeat Password:" label-for="password_confirm">
            <b-form-input
              id="password_confirm"
              v-model="form.password_confirm"
              type="password"
              placeholder="As above"
              :state="confirmationMatch"
              required>
            </b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary" :disabled="disableSubmit">Submit</b-button>

          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'RegisterForm',

  data: function() {
    return {
      form: {
        email: null,
        password: null,
        password_confirm: null
      }
    }
  },

  computed: {
    error: function() {
      return this.$store.state.auth.error
    },
    authenticated: function() {
      return this.$store.getters['auth/authenticated']
    },
    emailFormatOkay: function() {
      // TODO: better validation
      return /(.+)@(.+){2,}\.(.+){2,}/.test(this.$data.form.email)
    },
    confirmationMatch: function() {
      return this.$data.form.password == this.$data.form.password_confirm
    },
    disableSubmit: function() {
      return ! (this.emailFormatOkay && this.confirmationMatch)
    }
  },

  watch: {
    // TODO: this watch is here becasue we log the person in if we can register, there is no email confirmation yet
    authenticated: function(newVal, oldVal) {

      if(newVal && !oldVal) {
        this.$rollbar.info('login: atuhenticated became true');
        console.log("authenticated has changed from false to true")

        if(this.$route.params.nextUrl != null){
          this.$router.push(this.$route.params.nextUrl)
        } else {
          this.$router.push('/')
        }
      }

      if(oldVal && !newVal) {
        this.$rollbar.info('login: atuhenticated became false');
        console.log("authenticated has changed from true to false")

        this.$data.login.password = null
      }
    }
  },

  methods: {
    onSubmit: function(event) {
      event.preventDefault()
      this.$rollbar.info('register:onSubmit');
      this.$store.dispatch('auth/register', this.$data.form)
    },
    onReset: function(event) {
      event.preventDefault()
      this.$rollbar.info('register:onReset');
      this.$data.login.email = null
      this.$data.login.password = null
      this.$data.login.password_confirm = null
    },
  },
}
</script>

<style>
</style>
